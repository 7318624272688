import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RegisterAndLogin from "./Components/RegisterAndLogin";
import Chat from "./Components/Chat";

class RoutesNew extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<RegisterAndLogin />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/user/:email" element={<Chat />} />
            <Route path="/group/:groupId" element={<Chat />} />
          </Routes>
        </div>
        </BrowserRouter>
    );
  }
}

export default RoutesNew;
