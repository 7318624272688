import React, { Component } from 'react';
import RoutesNew from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

class App extends Component {
 
  render() {

    return (
      <>
        <RoutesNew />
      </>
    );
  }
}

export default App;
