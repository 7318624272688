// Import necessary modules
import React, { Component } from "react";
import { signOut } from "firebase/auth";
import {
    getAuth,
    onAuthStateChanged,
} from "firebase/auth";
import {
    getDatabase,
    ref,
    onValue,
    push,
    orderByChild,
    query,
    update,
    get,
    equalTo,
} from "firebase/database";
import {
    ArrowRight,
    ChatDotsFill,
    Check,
    CheckAll
} from "react-bootstrap-icons";
import { withRouter } from "../WithRouter";
import { database, messaging, onMessageListener } from "../Firebase";
import { getMessaging, getToken } from "firebase/messaging";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 5000,
});

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            newMessage: "",
            selectedFile: null,
            currentUser: null,
            userList: [],
            selectedUser: null,
            selectedGroup: null,
            chatRef: null,
            hasUnreadMessage: {},
            groupCreation: {
                groupName: "",
                selectedMembers: [],
            },
            groupMessages: [],
            groupUnreadStatus: {},
            unreadCountsBySender: {},
            unreadGroupMessages: {},
            imgUrl: [],
        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        const email = localStorage.getItem('email');
        if (!email) {
            window.location.href = '/';
        }
        const authInstance = getAuth();
        const db = getDatabase();

        // Use the messaging instance from firebase.js
        const messagingInstance = messaging;

        onMessageListener().then((payload) => {
            console.log("Foreground message received", payload);
            // Handle foreground message here
        });

        onAuthStateChanged(authInstance, async (user) => {
            if (user) {
                const storedUnreadMessages = JSON.parse(localStorage.getItem('unreadMessages')) || {};
                this.setState({ hasUnreadMessage: storedUnreadMessages });

                if (this.state.userGroups && this.state.userGroups.length > 0) {
                    const initialGroupUnreadStatus = {};
                    this.state.userGroups.forEach(group => {
                        initialGroupUnreadStatus[group.id] = false;
                    });
                    this.setState({ groupUnreadStatus: initialGroupUnreadStatus });
                }

                this.setState({ currentUser: user, messaging: messagingInstance });

                Notification.requestPermission().then((permission) => {
                    if (permission === "granted") {
                        const usersRef = ref(db, "users");
                        onValue(usersRef, (snapshot) => {
                            const data = snapshot.val();
                            if (data) {
                                const currentUserEmail = this.state.currentUser?.email || null;
                                const userList = Object.values(data).filter(
                                    (user) => user.email !== currentUserEmail
                                );
                                this.setState({ userList });
                            } else {
                                this.setState({ userList: [] });
                            }
                        });
                    } else {
                        console.error("Permission denied for notifications");
                    }
                });

                this.fetchUserGroups();

                const messagesRef = ref(db, "messages");
                const messagesQuery = query(messagesRef, orderByChild("timestamp"));

                onValue(messagesQuery, (snapshot) => {
                    this.updateGroupMessagesOnMount();
                    this.checkUnreadMessageCount();
                    if (snapshot && snapshot.val()) {
                        const data = snapshot.val();
                        const messageList = data ? Object.values(data) : [];

                        const updatedMessages = messageList.map((message) => {
                            if (
                                message.receiver === this.state.currentUser?.email &&
                                message.seen === false
                            ) {
                                update(ref(db, `messages/${message.id}`), {
                                    seen: true,
                                });
                            }

                            const isCurrentUserRecipient =
                                message.receiver === this.state.currentUser?.email;
                            const isUnseen =
                                !message.seenByReceiver && isCurrentUserRecipient;

                            return {
                                ...message,
                                seen: message.seen || false,
                                isUnseen,
                            };
                        });

                        const currentUserEmail = this.state.currentUser?.email || '';
                        const unreadCountsBySender = this.countUnreadMessagesBySender(updatedMessages, currentUserEmail);

                        this.setState({ messages: updatedMessages, unreadCountsBySender });
                    }
                });



                if ("serviceWorker" in navigator) {
                    navigator.serviceWorker.ready.then((registration) => {
                        messagingInstance.useServiceWorker(registration);
                    });

                    navigator.serviceWorker.addEventListener("message", (event) => {
                        const payload = event.data;

                        if (payload && payload.notification) {
                            console.log("Received background message", payload.notification.body);

                            const { body, title } = payload.notification;
                            const notificationOptions = {
                                body,
                                icon: "/path/to/icon.png",
                            };

                            return navigator.serviceWorker.ready.then((registration) =>
                                registration.showNotification(title, notificationOptions)
                            );
                        } else {
                            console.error("Invalid background message format", payload);
                        }
                    });
                } else {
                    console.error("Background messaging is not supported");
                }

                onMessageListener().then((payload) => {
                    console.log("Foreground message received", payload.notification.body);
                    Toast.fire({
                        title: payload.notification.title,
                        text: payload.notification.body,
                    });
                });
            }
        });
    }

    countUnreadMessagesBySender = (messages, currentUserEmail) => {
        // Filter messages where the receiver is the current user
        const messagesForCurrentUser = messages.filter(
            (message) => message.receiver === currentUserEmail
        );

        // Create a map to store counts for each sender
        const unreadCountBySender = {};

        // Calculate counts for each sender
        messagesForCurrentUser.forEach((message) => {
            const senderEmail = message.sender;

            // If seenByReceiver is true, set the count to 0, otherwise increment the count
            const count = message.isUnseen ? (unreadCountBySender[senderEmail] || 0) + 1 : 0;
            unreadCountBySender[senderEmail] = count;
        });

        return unreadCountBySender;
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.messages !== this.state.messages) {
            this.scrollToBottom();
        }
        if (prevState.groupMessages !== this.state.groupMessages) {
            this.scrollToBottom();
        }
    }

    fetchMessagesForSelectedUser = () => {
        const db = getDatabase();
        const messagesRef = ref(db, "messages");
        const { currentUser, selectedUser } = this.state;

        if (selectedUser) {
            const messagesQuery = query(messagesRef, orderByChild("timestamp"));

            onValue(messagesQuery, (snapshot) => {
                const data = snapshot.val();
                const messageList = data ? Object.values(data) : [];

                const filteredMessages = messageList.filter(
                    (message) =>
                        (message.sender === currentUser.email &&
                            message.receiver === selectedUser.email) ||
                        (message.sender === selectedUser.email &&
                            message.receiver === currentUser.email)
                );

                this.setState({ messages: filteredMessages }, () => {
                    this.scrollToBottom();

                    const updatedMessages = filteredMessages.map((message) => {
                        if (
                            message.receiver === currentUser.email &&
                            !message.seenByReceiver
                        ) {
                            const isChatOpen =
                                message.sender === selectedUser.email && !message.seenByReceiver;

                            update(ref(db, `messages/${message.id}`), {
                                seenByReceiver: isChatOpen,
                            });
                        }
                        return message;
                    });

                    this.setState({ messages: updatedMessages, selectedGroup: null });
                });

                const updatedUnreadMessages = { ...this.state.hasUnreadMessage };
                updatedUnreadMessages[selectedUser.email] = false;
                this.setState({
                    hasUnreadMessage: updatedUnreadMessages,
                });

                localStorage.setItem('unreadMessages', JSON.stringify(updatedUnreadMessages));
            });
        }
    };

    updateImgUrl = (url) => {
        this.setState((prevState) => ({
            imgUrl: [...prevState.imgUrl, { url }],
        }));
    };

    handleSendMessage = async () => {
        const db = getDatabase();
        const storage = getStorage();
        const messagesRef = ref(db, "messages");
        const { selectedUser, selectedFile, imgUrl } = this.state;

        try {
            // Create a new message and update the database
            const newMessageRef = push(messagesRef);
            const newMessageKey = newMessageRef.key;

            const newMessageData = {
                id: newMessageKey,
                fileName: selectedFile ? selectedFile.name : null,
                file: imgUrl.length > 0 ? null : selectedFile,
                image: imgUrl.length > 0 ? imgUrl[imgUrl.length - 1].url : null,
                text: this.state.newMessage,
                sender: this.state.currentUser.email,
                receiver: this.state.selectedUser?.email,
                timestamp: new Date().toISOString(),
                seenByReceiver: false,
            };

            if (selectedFile) {
                // Check file type
                const fileType = selectedFile.type;

                if (fileType.startsWith('image/')) {
                    // If it's an image, upload to Firebase Storage
                    const imgRef = storageRef(storage, `images/${selectedUser.id}/${newMessageKey}`);
                    const uploadTask = uploadBytes(imgRef, selectedFile);

                    // Handle the upload completion
                    uploadTask.then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            // Update the messages in the database with the image URL
                            newMessageData.image = url;
                            update(ref(db, `messages/${newMessageKey}`), newMessageData);
                        });
                    });
                } else {
                    // If it's a non-image file, upload to Firebase Storage
                    const fileRef = storageRef(storage, `files/${selectedUser.id}/${newMessageKey}`);
                    const uploadTask = uploadBytes(fileRef, selectedFile);

                    // Handle the upload completion
                    uploadTask.then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            // Update the messages in the database with the file URL
                            newMessageData.file = url;
                            update(ref(db, `messages/${newMessageKey}`), newMessageData);
                        });
                    });
                }
            } else {
                // If no file is selected, update the messages without the file URL
                await update(ref(db, `messages/${newMessageKey}`), newMessageData);
            }

            console.log("Message sent successfully.");

            // Clear the input field and fetch updated messages
            this.setState({ newMessage: "", selectedFile: null });
            this.fetchMessagesForSelectedUser();

            // Check if selectedUser exists and has email
            if (selectedUser && selectedUser.email) {
                // Request permission for push notifications
                const permission = await Notification.requestPermission();
                const selectedUserToken = selectedUser.recipientToken;
                const selectedUserOnlineStatus = selectedUser.onlineStatus;
                console.log(selectedUserOnlineStatus, 'selectedUserOnlineStatus');
                if (permission === 'granted') {
                    if (selectedUserOnlineStatus === true) {
                        // Initialize Firebase Messaging
                        // const messaging = getMessaging();

                        // Get the FCM token for the recipient
                        // const recipientToken = await getToken(messaging, { vapidKey: 'BDl-gsRFTI9KDdU7SdPOAuDw0phOnXY4uZH_vgUFjtqRH6LYzi8IXMxpKTi8Xv88yMwkbjUK2TWvrMty5VRbSXg' });
                        // console.log(recipientToken, 'recipientToken')
                        // Create the payload for the push notification
                        const notificationPayload = {
                            notification: {
                                title: 'New Message',
                                body: `You have a new message from ${this.state.currentUser.email}`,
                                icon: "/path/to/icon.png",  // Replace with the actual path to your icon
                            },
                            to: selectedUserToken,
                        };

                        // Send the push notification
                        const response = await fetch('https://fcm.googleapis.com/fcm/send', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'key=AAAAeVFegwU:APA91bH-vNDTrHzZ8itN52kkWG8oHuXyopZeaAMfjL69OnkgEVUysE_mJn__iJxP_dSTer969yQl42gxYdtiW6j78xWD_6x7ZMHl9cs8rzfyQMVbdZtlt_ia19q1sVMLBI6e1m1EIgp7',
                            },
                            body: JSON.stringify(notificationPayload),
                        });

                        if (response.ok) {
                            console.log("Push notification sent successfully.");
                        } else {
                            console.error("Failed to send push notification:", response.statusText);
                        }
                    }
                } else {
                    console.error("Permission denied for notifications");
                }
            }
        } catch (error) {
            console.error("Error sending message:", error.message);
        }
    };

    formatDate = (date) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    handleUserClick = (selectedUser) => {
        this.setState((prevState) => ({
            selectedUser,
            unreadCountsBySender: {
                ...prevState.unreadCountsBySender,
                [selectedUser.email]: 0, // Set count to 0 for the selected user
            },
            hasUnreadMessage: {
                ...prevState.hasUnreadMessage,
                [selectedUser.email]: 0, // Set count to 0 for the selected user
                selectedGroup: null,
            },
        }), () => {
            console.log(this.state.unreadCountsBySender, 'unreadCountsBySender');
            this.fetchMessagesForSelectedUser();
            this.scrollToBottom();
        });
    };

    handleSignOut = async (email) => {
        try {
            const authInstance = getAuth();

            // Sign out the user
            await signOut(authInstance);

            await this.updateOnlineStatus(email);
            localStorage.clear();

            this.props.router.navigate("/");

        } catch (error) {
            console.error("Error during sign-out:", error.message);
        }
    };

    updateOnlineStatus = async (email) => {
        const db = getDatabase();
        const usersRef = ref(db, 'users');

        try {
            const querySnapshot = await get(query(usersRef, orderByChild('email'), equalTo(email)));

            if (querySnapshot.exists()) {
                const userData = querySnapshot.val();
                const userId = Object.keys(userData)[0];
                const userRef = ref(database, `users/${userId}`);
                console.log(userId, 'user12');

                await update(userRef, { onlineStatus: false });

            } else {
                console.error('User not found with the specified email');
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    scrollToBottom = () => {
        const element = document.getElementsByClassName("scroll-down").length
            ? document.getElementsByClassName("scroll-down")[0]
            : "";
        if (element) {
            element.scrollTop = element.scrollHeight;
            element.addEventListener("scroll", this.trackScroll);
        }
    };

    handleCreateGroup = async () => {
        const { groupName, selectedMembers } = this.state.groupCreation;
        const db = getDatabase();
        const currentUser = this.state.currentUser;

        try {
            // Create a new group in the database
            const newGroupRef = push(ref(db, "groups"));
            const newGroupId = newGroupRef.key;

            const encodedGroupId = encodeURIComponent(newGroupId);

            const groupData = {
                id: newGroupId,
                name: groupName,
                members: [...selectedMembers, currentUser.email],
                creator: currentUser.email,
            };

            // Update the group data in the database
            await update(ref(db, `groups/${encodedGroupId}`), groupData);

            if (!Array.isArray(selectedMembers) || selectedMembers.length === 0) {
                console.error('No selected members or invalid selectedMembers array.');
                return;
            }



            console.log("Group created successfully.");

            // Clear the group creation form
            this.setState({
                groupCreation: {
                    groupName: "",
                    selectedMembers: [],
                },
            });

            // Fetch and display the updated user list
            const updatedUserList = this.filterUsersWithoutGroups(this.state.userList);
            this.setState({ userList: updatedUserList });

            // Fetch and display the updated user groups
            this.fetchUserGroups();

        } catch (error) {
            console.error("Error creating group:", error.message);
        }
    };

    filterUsersWithoutGroups = (userList) => {
        const { currentUser } = this.state;
        const userGroups = currentUser.groups || {};

        return userList.filter((user) => !userGroups[user.id]);
    };

    fetchUserGroups = () => {
        const db = getDatabase();
        const currentUserEmail = localStorage.getItem('email');

        try {
            if (currentUserEmail) {
                const userGroupsRef = ref(db, "groups");

                // Subscribe to real-time updates using onValue
                onValue(userGroupsRef, (snapshot) => {
                    if (snapshot.exists()) {
                        const userGroupsData = snapshot.val();

                        // Filter groups based on the current user's email
                        const filteredGroups = Object.values(userGroupsData).filter(group => {
                            return group.members.includes(currentUserEmail);
                        });

                        this.setState({ userGroups: filteredGroups });
                    }
                });
            }
        } catch (error) {
            console.error("Error fetching user groups:", error.message);
        }
    };

    handleAddMember = (email) => {
        this.setState((prevState) => ({
            groupCreation: {
                ...prevState.groupCreation,
                selectedMembers: [...prevState.groupCreation.selectedMembers, email],
            },
        }));
    };

    handleRemoveMember = (email) => {
        this.setState((prevState) => ({
            groupCreation: {
                ...prevState.groupCreation,
                selectedMembers: prevState.groupCreation.selectedMembers.filter(
                    (member) => member !== email
                ),
            },
        }));
    };

    sendGroupMessage = async (groupId, messageText, groupName) => {
        const db = getDatabase();
        const storage = getStorage();
        const groupMessagesRef = ref(db, `groups/${groupId}/messages`);
        const { selectedFile, imgUrl, selectedGroup } = this.state;

        try {
            const newMessageRef = push(groupMessagesRef);
            const newMessageKey = newMessageRef.key;

            // Get the list of group members
            const groupMembers = this.state.userGroups.find(group => group.id === groupId)?.members || [];

            // Exclude the sender from the list of recipients
            const recipients = groupMembers.filter(member => member !== this.state.currentUser.email);

            const senderEmail = this.state.currentUser.email;

            // Fetch the user's email and remove duplicates
            const uniqueRecipients = [...new Set(recipients)];

            // Initialize openedByMembers with sender's email
            const openedByMembers = [senderEmail];

            const newMessageData = {
                id: newMessageKey,
                fileName: selectedFile ? selectedFile.name : null,
                file: imgUrl.length > 0 ? null : selectedFile,
                image: imgUrl.length > 0 ? imgUrl[imgUrl.length - 1].url : null,
                text: messageText,
                sender: senderEmail,
                timestamp: new Date().toISOString(),
                recipients: uniqueRecipients,
                seenByAllReceiver: false,
                openedByMembers: openedByMembers,
            };

            if (selectedFile) {
                // Check file type
                const fileType = selectedFile.type;

                if (fileType.startsWith('image/')) {
                    // If it's an image, upload to Firebase Storage
                    const imgRef = storageRef(storage, `images/${selectedGroup.id}/${newMessageKey}`);
                    const uploadTask = uploadBytes(imgRef, selectedFile);

                    // Handle the upload completion
                    uploadTask.then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            // Update the messages in the database with the image URL
                            newMessageData.image = url;
                            update(ref(db, `groups/${groupId}/messages/${newMessageKey}`), newMessageData);
                        });
                    });
                } else {
                    // If it's a non-image file, upload to Firebase Storage
                    const fileRef = storageRef(storage, `files/${selectedGroup.id}/${newMessageKey}`);
                    const uploadTask = uploadBytes(fileRef, selectedFile);

                    // Handle the upload completion
                    uploadTask.then((snapshot) => {
                        getDownloadURL(snapshot.ref).then((url) => {
                            // Update the messages in the database with the file URL
                            newMessageData.file = url;
                            update(ref(db, `groups/${groupId}/messages/${newMessageKey}`), newMessageData);
                        });
                    });
                }
            } else {
                // If no file is selected, update the messages without the file URL
                await update(ref(db, `groups/${groupId}/messages/${newMessageKey}`), newMessageData);
            }
            // Update the group messages in the database

            console.log("Group message sent successfully.");

            await this.checkUnreadMessageCount(groupId);

            // Fetch updated group messages
            this.fetchGroupMessages(groupId);

            // Check if any recipient is not currently logged in and mark the message as unread
            recipients.forEach((recipient) => {
                const recipientLoggedIn = this.state.userList.some(
                    (user) => user.email === recipient
                );
                if (!recipientLoggedIn) {
                    this.setState((prevState) => ({
                        hasUnreadMessage: {
                            ...prevState.hasUnreadMessage,
                            [recipient]: true,
                        },
                    }));
                }
            });

            this.setState({ newMessage: "", selectedFile: null });

            // Send notifications to all group members
            await this.sendNotificationsToGroupMembers(uniqueRecipients, senderEmail, messageText, groupName);

        } catch (error) {
            console.error("Error sending group message:", error.message);
        }
    };

    sendNotificationsToGroupMembers = async (recipients, senderEmail, messageText, groupName) => {
        try {
            for (const recipientEmail of recipients) {
                // Get the FCM token for the recipient
                const recipientToken = await this.getRecipientTokenByEmail(recipientEmail);

                // Check if the recipient has a valid FCM token
                if (recipientToken) {
                    const notificationPayload = {
                        notification: {
                            title: `New Group Message in ${groupName}`,
                            body: `${messageText ? messageText : 'File'} by ${senderEmail}`,
                            icon: "/path/to/icon.png",  // Replace with the actual path to your icon
                        },
                        to: recipientToken,
                    };

                    // Send the push notification
                    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'key=AAAAeVFegwU:APA91bH-vNDTrHzZ8itN52kkWG8oHuXyopZeaAMfjL69OnkgEVUysE_mJn__iJxP_dSTer969yQl42gxYdtiW6j78xWD_6x7ZMHl9cs8rzfyQMVbdZtlt_ia19q1sVMLBI6e1m1EIgp7', // Replace with your server key
                        },
                        body: JSON.stringify(notificationPayload),
                    });

                    if (response.ok) {
                        console.log(`Push notification sent successfully to ${recipientEmail}`);
                    } else {
                        console.error(`Failed to send push notification to ${recipientEmail}:`, response.statusText);
                    }
                } else {
                    console.error(`Recipient ${recipientEmail} does not have a valid FCM token.`);
                }
            }
        } catch (error) {
            console.error("Error sending notifications to group members:", error.message);
        }
    };

    getRecipientTokenByEmail = async (recipientEmail) => {
        const db = getDatabase();
        const usersRef = ref(db, 'users');

        try {
            const querySnapshot = await get(query(usersRef, orderByChild('email'), equalTo(recipientEmail)));

            if (querySnapshot.exists()) {
                const userData = querySnapshot.val();
                const userId = Object.keys(userData)[0];
                const user = userData[userId];
                if (user.onlineStatus === true) {
                    console.log(userId, user, 'user12');
                    if (user.recipientToken) {
                        return user.recipientToken;
                    } else {
                        console.error('Recipient token not found for the user');

                        return null;
                    }
                }
            } else {
                console.error('User not found with the specified email');
                return null;
            }
        } catch (error) {
            console.error('Error fetching user data:', error.message);
            return null;
        }
    };

    fetchGroupMessages = (groupId) => {
        const db = getDatabase();
        const groupMessagesRef = ref(db, `groups/${groupId}/messages`);
        console.log('aa')
        onValue(groupMessagesRef, (snapshot) => {
            console.log('call')
            try {
                if (snapshot.exists()) {
                    const messages = snapshot.val() || {};
                    const groupMessages = Object.values(messages);

                    this.checkUnreadMessageCount(groupId);
                    this.markGroupMessagesAsSeen(groupId);

                    this.setState({ groupMessages: groupMessages }, () => {
                        // Check if all recipients have seen each message
                        const updatedGroupMessages = groupMessages.map(message => {
                            const allRecipientsOpened = message.recipients.every(
                                recipient => message.openedByMembers.includes(recipient)
                            );

                            // Update the 'seenByAllReceiver' flag if all recipients have opened
                            if (allRecipientsOpened && !message.seenByAllReceiver) {
                                update(ref(db, `groups/${groupId}/messages/${message.id}`), {
                                    seenByAllReceiver: true,
                                });
                            }

                            return message;
                        });

                        this.setState({ groupMessages: updatedGroupMessages });

                        // Check if there are unread messages and update groupUnreadStatus
                        const hasUnreadMessages = updatedGroupMessages.some(message => {
                            const isCurrentUserRecipient = message.recipients.includes(this.state.currentUser.email);
                            return !message.seenByAllReceiver && isCurrentUserRecipient;
                        });

                        this.setState((prevState) => {
                            const newGroupUnreadStatus = {
                                ...prevState.groupUnreadStatus,
                                [groupId]: hasUnreadMessages,
                            };
                            return { groupUnreadStatus: newGroupUnreadStatus };
                        });

                        // Count of messages in the group
                        const messageCount = groupMessages.length;
                        console.log(`Group ${groupId} has ${messageCount} messages.`);
                    });
                } else {
                    console.error(`Snapshot for group ${groupId} does not exist.`);
                }
            } catch (error) {
                console.error("Error processing group messages snapshot:", error.message);
            }
        });
    };

    handleGroupClick = async (groupId) => {
        await this.updateGroupMessagesOnMount();
        const db = getDatabase();
        const groupRef = ref(db, `groups/${groupId}`);
        const messagesRef = ref(db, `groups/${groupId}/messages`);
        const receiverEmail = this.state.currentUser.email;

        try {
            const groupSnapshot = await get(groupRef);
            const selectedGroup = groupSnapshot.val();

            if (selectedGroup) {
                const snapshot = await get(messagesRef);
                const data = snapshot.val();
                const groupMessages = data ? Object.values(data) : [];

                this.setState({
                    selectedGroup,
                    selectedUser: null,
                    groupMessages,
                }, () => {
                    this.scrollToBottom();
                    this.fetchGroupMessages(groupId);
                });

                groupMessages.forEach(message => {
                    // Check if the current user's email is not already in openedByMembers
                    if (!message.openedByMembers.includes(receiverEmail)) {
                        // Push the current user's email into 'openedByMembers'
                        message.openedByMembers.push(receiverEmail);

                        // Update the 'openedByMembers' array in the database
                        update(ref(db, `groups/${groupId}/messages/${message.id}`), {
                            openedByMembers: message.openedByMembers,
                        });

                        // Check if openedByMembers and group members have the same length
                        const groupMembers = selectedGroup.members || [];
                        if (message.openedByMembers.length === groupMembers.length) {
                            // Update the 'seenByAllReceiver' flag
                            if (!message.seenByAllReceiver) {
                                update(ref(db, `groups/${groupId}/messages/${message.id}`), {
                                    seenByAllReceiver: true,
                                });
                            }
                        }
                    }
                });

                // Mark the group as read when clicked
                this.setState((prevState) => {
                    const newGroupUnreadStatus = {
                        ...prevState.groupUnreadStatus,
                        [groupId]: false,
                    };
                    return { groupUnreadStatus: newGroupUnreadStatus };
                });
            }
        } catch (error) {
            console.error("Error handling group click:", error.message);
        }
    };

    updateGroupMessagesOnMount = async () => {
        const { selectedGroup } = this.state;
        if (selectedGroup) {
            const groupId = selectedGroup.id;
            this.fetchGroupMessages(groupId);
            this.markGroupMessagesAsSeen(groupId);
            this.checkUnreadMessageCount(groupId);
        }
    };

    markGroupMessagesAsSeen = async (groupId) => {
        const db = getDatabase();
        const messagesRef = ref(db, `groups/${groupId}/messages`);
        const receiverEmail = this.state.currentUser.email;
        const { selectedGroup } = this.state; // Add this line

        try {
            const snapshot = await get(messagesRef);
            const data = snapshot.val();
            const groupMessages = data ? Object.values(data) : [];

            groupMessages.forEach(async (message) => {
                if (!message.openedByMembers.includes(receiverEmail)) {
                    message.openedByMembers.push(receiverEmail);
                    await update(ref(db, `groups/${groupId}/messages/${message.id}`), {
                        openedByMembers: message.openedByMembers,
                    });

                    // Check if all members have seen the message
                    if (message.openedByMembers.length === selectedGroup.members.length) {
                        await update(ref(db, `groups/${groupId}/messages/${message.id}`), {
                            seenByAllReceiver: true,
                        });
                    }
                }
            });
        } catch (error) {
            console.error("Error marking group messages as seen:", error.message);
        }
    };

    checkUnreadMessageCount = (groupId) => {
        const db = getDatabase();
        const userGroups = this.state.userGroups || [];

        // Listen for changes at the user groups level
        userGroups.forEach((group) => {
            const groupId = group.id;
            const groupMessagesRef = ref(db, `groups/${groupId}/messages`);

            onValue(groupMessagesRef, (snapshot) => {
                try {
                    if (snapshot.exists()) {
                        const messages = snapshot.val() || {};
                        const groupMessages = Object.values(messages);

                        const receiverEmail = this.state.currentUser.email;

                        // Find messages where the user is not in openedByMembers
                        const unreadMessages = groupMessages.filter(
                            (message) => !message.openedByMembers.includes(receiverEmail)
                        );

                        // Count the number of unread messages
                        const unreadMessageCount = unreadMessages.length;
                        this.setState({
                            unreadGroupMessages: { [groupId]: unreadMessageCount },
                        })
                        this.setState((prevState) => ({
                            unreadGroupMessages: {
                                ...prevState.unreadGroupMessages,
                                [groupId]: unreadMessageCount,
                            },
                        }));

                        // Display or store the unreadMessageCount as needed
                        console.log(`User has ${unreadMessageCount} unread messages in group ${groupId}`);
                    } else {
                        console.error(`Snapshot for group ${groupId} does not exist.`);
                    }
                } catch (error) {
                    console.error("Error processing group messages snapshot:", error.message);
                }
            });
        });
    };

    handleFileSelect = (e) => {
        this.setState({ selectedFile: e.target.files[0] });
    };

    handleDownload = (data) => {
        window.open(data, '_blank');
    };

    handleRemoveSelectedFile = () => {
        this.setState({
            selectedFile: null
        })
    }

    render() {
        const email = localStorage.getItem("email");
        const { messages, newMessage, userList, selectedUser, groupCreation, userGroups, groupMessages, selectedGroup, unreadGroupMessages, selectedFile } = this.state;
        const validUsers = userList.filter((user) => user.email);
        return (
            <div className="container mt-5">
                <div className="card-header d-flex justify-content-between align-items-center card-header-bg text-white mb-5" style={{ padding: "10px" }}>
                    <h4 style={{ width: "65%" }}>
                        Username: {email}
                    </h4>
                    <div style={{ width: "30%", marginLeft: "10px" }}>
                        <button
                            style={{ minWidth: "100px", float: "right" }}
                            className="btn btn-danger"
                            onClick={() => this.handleSignOut(email)}
                        >
                            Sign Out
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center card-header-bg text-white">
                            <h2 style={{ marginLeft: "10px" }}>User List</h2>
                        </div>
                        <div className="list-group">
                            {validUsers && validUsers.length > 0 ? (
                                validUsers.map((user, index) => {
                                    return <button
                                        key={index}
                                        type="button"
                                        className={`list-group-item list-group-item-action ${selectedUser && selectedUser.email === user.email ? "active" : ""}`}
                                        onClick={() => this.handleUserClick(user)}
                                    >
                                        {user.email}{user.onlineStatus === true ? <span className="online-dot" key={index}></span> : ""}

                                        {this.state.unreadCountsBySender && this.state.unreadCountsBySender[user.email] > 0 && (
                                            <span style={{ color: 'red', marginLeft: '5px' }}>{this.state.unreadCountsBySender[user.email]}</span>
                                        )}
                                    </button>
                                })
                            ) : (
                                <p style={{ padding: "10px", textAlign: "center" }}>No users found.</p>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                        <div className="card-header d-flex justify-content-between align-items-center card-header-bg text-white">
                            <h2 style={{ marginLeft: "10px" }}>Group List</h2>
                        </div>
                        <div className="list-group">
                            {userGroups && userGroups.length > 0 ? (
                                userGroups.map((group) => (
                                    <button
                                        key={group.id}
                                        type="button"
                                        className={`list-group-item list-group-item-action ${selectedGroup && selectedGroup.id === group.id ? "active" : ""}`}
                                        onClick={() => this.handleGroupClick(group.id)}
                                    >
                                        {group.name}
                                        {unreadGroupMessages && unreadGroupMessages[group.id] > 0 && (
                                            <span style={{ color: 'red', marginLeft: '5px' }}>{unreadGroupMessages[group.id]}</span>
                                        )}

                                    </button>
                                ))
                            ) : (
                                <p style={{ padding: "10px", textAlign: "center" }}>You are not in any group.</p>
                            )}

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="card-header d-flex justify-content-between align-items-center card-header-bg text-white">
                            <h2 style={{ width: "65%", marginLeft: "10px" }}>
                                Create a Group
                            </h2>
                        </div>
                        <div className="card p-3">
                            <div className="form-group mb-2">
                                <label htmlFor="groupName" className="mb-2 ">Group Name:</label>
                                <input
                                    type="text"
                                    id="groupName"
                                    className="form-control"
                                    value={groupCreation.groupName}
                                    onChange={(e) =>
                                        this.setState({
                                            groupCreation: {
                                                ...groupCreation,
                                                groupName: e.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <label>Select Members:</label>
                                <ul className="list-group">
                                    {userList.map((user, index) => (
                                        <li
                                            key={index}
                                            className="list-group-item d-flex justify-content-between align-items-center"
                                        >
                                            {user.email}
                                            {groupCreation.selectedMembers.includes(user.email) ? (
                                                <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => this.handleRemoveMember(user.email)}
                                                >
                                                    Remove
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn btn-success btn-sm"
                                                    onClick={() => this.handleAddMember(user.email)}
                                                >
                                                    Add
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <button
                                className="btn card-header-bg mt-3"
                                onClick={this.handleCreateGroup}
                                disabled={!groupCreation.groupName || groupCreation.selectedMembers.length === 0}
                            >
                                <b>Create Group</b>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 mt-5">
                        {selectedUser && !selectedUser.groupId && (
                            <div className="card">
                                <div className="card-header card-header-bg d-flex justify-content-between align-items-center text-white">
                                    <h4 style={{ width: "100%" }}>
                                        Chatting with {selectedUser.email}
                                    </h4>
                                </div>
                                <div
                                    className="card-body scroll-down"
                                    style={{ overflowY: "auto", maxHeight: "400px" }}
                                >
                                    {messages.length === 0 ? (
                                        <p>No messages</p>
                                    ) : (
                                        <div
                                            ref={this.ref}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            {messages.map((message, index) => {
                                                const isSender = message.sender === email;
                                                const isSeen = message.seenByReceiver === true;
                                                const senderName = message.sender ? message.sender.split("@")[0] : "";

                                                return (
                                                    <div
                                                        key={index}
                                                        className={`card mb-2 ${isSender ? "align-self-end bg-info" : "align-self-start bg-success"}`}
                                                        style={{
                                                            maxWidth: "400px",
                                                            borderRadius: "8px",
                                                            marginLeft: isSender ? "auto" : "0",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h6 className="card-title">{this.formatDate(message.timestamp)}</h6>
                                                        </div>
                                                        <div className="card-body pb-0">
                                                            {message.text && <p className="card-text">{message.text}</p>}
                                                            {message.file && (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <p style={{ margin: '0px' }}>{message.fileName}</p>
                                                                    <i style={{ marginLeft: '5px' }} className="fa fa-eye" aria-hidden="true" onClick={() => this.handleDownload(message.file)}></i>
                                                                </div>
                                                            )}

                                                            {message.image && (
                                                                <div>
                                                                    <img src={message.image} alt="Image" onClick={() => this.handleDownload(message.image)} style={{ maxWidth: "100%" }} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="card-body pb-0">
                                                            <span className="card-subtitle text-muted d-flex justify-content-end" style={{ "paddingBottom": isSender ? "" : "15px" }}>
                                                                {senderName} - {" "}
                                                                {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}

                                                                {isSender && (
                                                                    <>
                                                                        {isSeen ? (
                                                                            <p className="text-muted">
                                                                                <CheckAll style={{ color: 'blue' }} size={25} />
                                                                            </p>
                                                                        ) : (
                                                                            <>
                                                                                {selectedUser.onlineStatus ? (
                                                                                    <p className="text-muted">
                                                                                        <CheckAll style={{ color: 'white' }} size={25} />
                                                                                    </p>
                                                                                ) : (
                                                                                    <p className="text-muted">
                                                                                        <Check style={{ color: 'white' }} size={25} />
                                                                                    </p>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                        </div>
                                    )}
                                </div>
                                <div className="card-footer">
                                    {selectedFile && (
                                        <div className="preview-container">
                                            {selectedFile.type.startsWith('image/') ? (
                                                <>
                                                    <img
                                                        src={URL.createObjectURL(selectedFile)}
                                                        alt="Selected Image single"
                                                        className="image-preview"
                                                        style={{ width: '100px' }}
                                                    />
                                                    <i className="fa fa-close cancle-icon" aria-hidden="true" onClick={() => this.handleRemoveSelectedFile()}></i>
                                                </>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p style={{ margin: '0px' }}>{selectedFile.name}</p>
                                                    <i style={{ marginLeft: '5px' }} className="fa fa-close" aria-hidden="true" onClick={() => this.handleRemoveSelectedFile()}></i>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type your message..."
                                            value={newMessage}
                                            onChange={(e) =>
                                                this.setState({ newMessage: e.target.value })
                                            }
                                        />
                                        <label htmlFor="fileInput">
                                            <i className="fa fa-paperclip attachment-icon" aria-hidden="true"></i>
                                        </label>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => this.handleFileSelect(e)}
                                            accept="image/*, application/pdf"
                                        />

                                        <button
                                            className="btn btn-primary ml-2"
                                            style={{ marginLeft: "10px" }}
                                            onClick={this.handleSendMessage}
                                            disabled={!newMessage.trim() && !selectedFile}
                                        >
                                            <ArrowRight size={30} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {selectedGroup && (
                            <div className="card">
                                <div className="card-header card-header-bg d-flex justify-content-between align-items-center text-white">
                                    <h4 style={{ width: "65%" }}>
                                        Chatting in Group : {selectedGroup.name}
                                    </h4>
                                </div>

                                <div
                                    className="card-body scroll-down"
                                    style={{ overflowY: "auto", maxHeight: "400px" }}
                                >
                                    {groupMessages.length === 0 ? (
                                        <div className="d-flex justify-content-center align-item-center">
                                            <p>No messages</p>
                                        </div>
                                    ) : (
                                        <div
                                            ref={this.ref}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                            }}
                                        >
                                            {groupMessages.map((message, index) => {
                                                const isSender = message.sender === email;
                                                const isSeen = message.seenByAllReceiver === true;
                                                const senderName = message.sender ? message.sender.split("@")[0] : "";
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`card mb-2 ${isSender
                                                            ? "align-self-end bg-info"
                                                            : "align-self-start bg-success"
                                                            } text-white`}
                                                        style={{
                                                            maxWidth: "400px",
                                                            borderRadius: "8px",
                                                            marginLeft: isSender ? "auto" : "0",
                                                        }}
                                                    >
                                                        <div className="card-header">
                                                            <h6 className="card-title">
                                                                {this.formatDate(message.timestamp)}
                                                            </h6>
                                                        </div>
                                                        <div className="card-body pb-0">
                                                            <p className="card-text">{message.text}</p>
                                                            {message.file && (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <p style={{ margin: '0px' }}>{message.fileName}</p>
                                                                    <i style={{ marginLeft: '5px' }} className="fa fa-eye" aria-hidden="true" onClick={() => this.handleDownload(message.file)}></i>
                                                                </div>
                                                            )}

                                                            {message.image && (
                                                                <div>
                                                                    <img src={message.image} alt="Image" onClick={() => this.handleDownload(message.image)} style={{ maxWidth: "100%" }} />
                                                                </div>
                                                            )}
                                                            <span className="card-subtitle text-muted d-flex justify-content-end" style={{ "paddingBottom": isSender ? "" : "15px" }}>
                                                                {senderName} - {" "}
                                                                {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}

                                                                {isSender && (
                                                                    <>
                                                                        {isSeen ? (
                                                                            <p className="text-muted">
                                                                                <CheckAll style={{ color: 'blue' }} size={25} />
                                                                            </p>
                                                                        ) : (
                                                                            <p className="text-muted">
                                                                                <Check style={{ color: 'white' }} size={25} />
                                                                            </p>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className="card-footer">
                                    {selectedFile && (
                                        <div className="preview-container">
                                            {selectedFile.type.startsWith('image/') ? (
                                                <>
                                                    <img
                                                        src={URL.createObjectURL(selectedFile)}
                                                        alt="Selected Image single"
                                                        className="image-preview"
                                                        style={{ width: '100px' }}
                                                    />
                                                    <i className="fa fa-close cancle-icon" aria-hidden="true" onClick={() => this.handleRemoveSelectedFile()}></i>
                                                </>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <p style={{ margin: '0px' }}>{selectedFile.name}</p>
                                                    <i style={{ marginLeft: '5px' }} className="fa fa-close" aria-hidden="true" onClick={() => this.handleRemoveSelectedFile()}></i>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Type your message..."
                                            value={newMessage}
                                            onChange={(e) =>
                                                this.setState({ newMessage: e.target.value })
                                            }
                                        />

                                        <label htmlFor="fileInput">
                                            <i className="fa fa-paperclip attachment-icon" aria-hidden="true"></i>
                                        </label>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => this.handleFileSelect(e)}
                                            accept="image/*, application/pdf"
                                        />

                                        <button
                                            className="btn btn-primary ml-2"
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => this.sendGroupMessage(selectedGroup.id, newMessage, selectedGroup.name)}
                                            disabled={!newMessage.trim() && !selectedFile}
                                        >
                                            <ArrowRight size={30} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Profile);
