import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export function withRouter(Component) {
    const ComponentWithRouterProps = (props) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();
        return (<Component {...props} router={{location,params,navigate}} />
        )
    }  
    return ComponentWithRouterProps;
}
