import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getMessaging, onMessage } from 'firebase/messaging'; 

const firebaseConfig = {
    apiKey: "AIzaSyCZhPrmJNk0ovPNqaYgoUuYoZ-nULmg6L0",
    authDomain: "chat-app-demo-8b1a6.firebaseapp.com",
    projectId: "chat-app-demo-8b1a6",
    storageBucket: "chat-app-demo-8b1a6.appspot.com",
    messagingSenderId: "521056191237",
    appId: "1:521056191237:web:ddfe957f58e8d98fdf6d6c",
    databaseURL: "https://chat-app-demo-8b1a6-default-rtdb.firebaseio.com",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

// Use the messaging instance from firebase.js
const messaging = getMessaging(app);

// Create a promise-based listener for incoming messages
const onMessageListener = () =>
  new Promise((resolve) => {
    // Create an event listener for foreground messages
    const unsubscribe = onMessage(messaging, (payload) => {
      resolve(payload);
    });

    // Return a function to unsubscribe the listener when needed
    return unsubscribe;
  });

export { app, auth, database, messaging, onMessageListener };
