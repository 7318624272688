// src/components/RegisterAndLogin.jsx
import React, { Component } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { withRouter } from "../WithRouter";
import { auth, database, messaging } from "../Firebase";
import { Card, Form, Button, Tabs, Tab, Container, Row, Col } from 'react-bootstrap';
import { child, get, getDatabase, push, ref, set, update } from "firebase/database";
import { getMessaging, getToken } from "firebase/messaging";
import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});


class RegisterAndLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      login: false,
      email: "",
      password: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  componentDidMount = () => {
    const email = localStorage.getItem("email");
    if (email) {
      window.location.href = '/chat';
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // Handle permission granted
        } else {
          console.error('Notification permission denied.');
          // Handle the case where notification permission is not granted
        }
      });
    }
  }

  // subscribeToPushNotifications = async (registration) => {
  //   try {
  //     const subscription = await registration.pushManager.subscribe({
  //       userVisibleOnly: true,
  //       applicationServerKey: 'BDl-gsRFTI9KDdU7SdPOAuDw0phOnXY4uZH_vgUFjtqRH6LYzi8IXMxpKTi8Xv88yMwkbjUK2TWvrMty5VRbSXg',
  //     });

  //     const userRef = push(ref(database, "users"));
  //     const userId = userRef.key;

  //     set(userRef, {
  //       id: userId,
  //       email: this.state.email,
  //       recipientToken: subscription.endpoint,
  //     });

  //     Toast.fire({
  //       icon: 'success',
  //       title: 'Subscription Successful!',
  //       text: `Welcome, ${this.state.email}!`,
  //     });
  //   } catch (error) {
  //     console.error('Error subscribing to push notifications:', error);
  //     Toast.fire({
  //       icon: 'error',
  //       title: 'Error subscribing to push notifications',
  //       text: error.message,
  //     });
  //   }
  // }

  handleSubmit = async (e, type) => {
    e.preventDefault();
    const email = this.state.email;
    const password = this.state.password;

    if (type === "signup") {
      createUserWithEmailAndPassword(auth, email, password)
        .then((data) => {
          const user = data.user;

          // const messaging = getMessaging();

          getToken(messaging, { vapidKey: 'BDl-gsRFTI9KDdU7SdPOAuDw0phOnXY4uZH_vgUFjtqRH6LYzi8IXMxpKTi8Xv88yMwkbjUK2TWvrMty5VRbSXg' })
            .then((res) => {
              const userRef = push(ref(database, "users"));
              const userId = userRef.key;
              if (res) {
                set(userRef, {
                  id: userId,
                  email: user.email,
                  recipientToken: res,
                  onlineStatus: true,
                });

                Toast.fire({
                  icon: 'success',
                  title: 'Signup Successful!',
                  text: `Welcome, ${user.email}!`,
                });

                console.log(user.email, "authData");
                localStorage.setItem('email', user.email);
                this.props.router.navigate("/chat");
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Error getting token',
                });
              }
            })
            .catch((error) => {
              console.error('Error getting token:', error);
              Toast.fire({
                icon: 'error',
                title: 'Error getting token:',
                text: error,
              });
            });
        })
        .catch((err) => {
          console.error("Signup Error:", err);
          // alert(err.code);
          this.setState({ login: false });

          Toast.fire({
            icon: 'error',
            title: 'Signup Failed',
            text: err.message,
          });
        });
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then(async (data) => {
          localStorage.setItem('email', data.user.email);

          const db = getDatabase();
          const usersRef = ref(db, "users");

          // Check if the user with the provided email exists
          const userSnapshot = await get(usersRef);
          const userFound = Object.values(userSnapshot.val()).find(user => user.email === email);

          if (userFound) {
            getToken(messaging, { vapidKey: 'BDl-gsRFTI9KDdU7SdPOAuDw0phOnXY4uZH_vgUFjtqRH6LYzi8IXMxpKTi8Xv88yMwkbjUK2TWvrMty5VRbSXg' })
              .then((res) => {
                console.log(res, 'res')
                // Update the recipientToken for the existing user
                const userId = userFound.id;
                const userRef = ref(database, `users/${userId}`);

                update(userRef, {
                  recipientToken: res,
                  onlineStatus: true
                });
              })
              .catch((error) => {
                console.error('Error getting token:', error);
                Toast.fire({
                  icon: 'error',
                  title: 'Error getting token:',
                  text: error,
                });
              });

            // User exists, proceed with navigation and success message
            this.props.router.navigate("/chat");
            Toast.fire({
              icon: 'success',
              title: 'Login Successful!',
              text: `Welcome back, ${data.user.email}!`,
            });
          } else {
            // User does not exist, show an error message
            Toast.fire({
              icon: 'error',
              title: 'Login Failed',
              text: 'User does not exist.',
            });
          }
        })
        .catch((err) => {
          // Handle login errors
          Toast.fire({
            icon: 'error',
            title: 'Login Failed',
            text: err.message,
          });
        });
    }
  };

  render() {
    return (
      <Container fluid className="App">
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
            <Card className="mt-3" center>
              <Card.Body>
                <Tabs defaultActiveKey="signup" id="uncontrolled-tab-example" className="mb-3">
                  <Tab eventKey="signup" title="Sign Up">
                    <h1>SignUp</h1>
                    <Form onSubmit={(e) => this.handleSubmit(e, "signup")}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword" className="mt-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password" onChange={this.handleChange} />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="mt-3">
                        SignUp
                      </Button>
                    </Form>
                  </Tab>
                  <Tab eventKey="signin" title="Sign In">
                    <h1>SignIn</h1>
                    <Form onSubmit={(e) => this.handleSubmit(e, "signin")}>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter email" onChange={this.handleChange} />
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword" className="mt-3">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" placeholder="Password" onChange={this.handleChange} />
                      </Form.Group>
                      <Button variant="primary" type="submit" className="mt-3">
                        SignIn
                      </Button>
                    </Form>
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(RegisterAndLogin);
